@media (min-width: 0px) {
  .header_area .menu_nav {
    width: auto !important;
  }
}

@media (min-width: 0px) {
  .logo_h {
    left: 0 !important;
  }
}
.header_area {
  transition: box-shadow 0.5s;
}

.HeaderPMEAuditeCss {
  color: rgba(34, 34, 34, 0.78) !important;
}
.nav-items {
  right: 350;
}
